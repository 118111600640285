import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Footer from "./components/Footer";
import Header from "./components/Header";
import SkaparPage from "./components/skapar/SkaparPage";
import {connect} from "react-redux";
import {signIn} from "./actions";
import {MsalAuthenticationTemplate, useAccount, useMsal} from "@azure/msal-react";
import {InteractionType} from "@azure/msal-browser";
import {ErrorComponent} from "./components/ErrorComponent";
import {Loading} from "./components/Loading";
import {loginRequest} from "./authConfig";
import {useEffect, useState} from "react";
import verifyToken from "./utils/verifyToken";

const App = ({signIn}) => {
    const authRequest = {
        ...loginRequest
    };

    const { instance, accounts, inProgress } = useMsal();
    const account = useAccount(accounts[0] || {});

    useEffect(() => {
        if (account && inProgress === "none") {
            instance.acquireTokenSilent({
                ...loginRequest,
                account: account
            }).then((response) => {
                verifyToken(response.idToken).then(r => {
                    signIn(account.username, response.idToken)
                })
            });
        }
    }, [account, inProgress, instance]);

    return (
      <MsalAuthenticationTemplate
          interactionType={InteractionType.Redirect}
          authenticationRequest={authRequest}
          errorComponent={ErrorComponent}
          loadingComponent={Loading}
      >
          <Router>
          <div>
            <div className="relative items-center justify-center">
              <Header />
              <SkaparPage />
              <Footer />
            </div>
          </div>
          </Router>
      </MsalAuthenticationTemplate>
  );
}

export default connect(
    null,
    { signIn }
)(App);
