import { combineReducers } from 'redux';
// import { reducer as formReducer } from 'redux-form';
import authReducer from './authReducer';
import skaparReducer from './skaparReducer';
import cancelModalReducer from './cancelModalReducer';
import reserveModalReducer from './reserveModalReducer';


export default combineReducers({
    auth: authReducer,
    skapar: skaparReducer,
    reserveModal: reserveModalReducer,
    cancelModal: cancelModalReducer,
});
