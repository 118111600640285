import React, {  useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchSkapar } from '../../actions';
import Locker from '../Locker';
import CancellationModal from "../CancellationModal";
import ReservationModal from "../ReservationModal";
var _ = require('lodash');


const SkaparList = ({skapar, fetchSkapar, isSignedIn, authToken}) => {

    useEffect(() =>  {
        fetchSkapar(authToken);
    }, [fetchSkapar, authToken]);


    const resolveLockers = (lockers) => {
        var res = []
        for (var k = 0; k < 4; k++) {
            for (var i = 0; i < 12; i++) {
                var number = i * 4 + k + 1;
                const rented = _.filter(lockers, { number: number, endDate: ""});
                if (rented === undefined || rented.length === 0) {
                    res.push({
                        number: number,
                        userEmail: "",
                        startDate: null,
                        reserved: false,
                    })
                } else {
                    const rec = rented[0]
                    res.push({
                        number: number,
                        userEmail: rec.userEmail,
                        startDate: rec.startDate,
                        reserved: true,
                    })
                }
            }
        }
        return res
    }

    const renderList = () => {
        const lockers = resolveLockers(skapar)
        return (
            <>
                <div className="grid grid-cols-12 gap-4">
                    {lockers.map(( locker ) => <Locker key={locker.number} locker={locker} />)}
                </div>
                <CancellationModal />
                <ReservationModal />
            </>
        )
    }

    return isSignedIn && (
        <div>
            <div className="ui celled list">{renderList()}</div>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        skapar: Object.values(state.skapar),
        isSignedIn: state.auth.isSignedIn,
        authToken: state.auth.authToken,
    };
};

export default connect(
    mapStateToProps,
    { fetchSkapar }
)(SkaparList);
