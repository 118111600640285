import {
    SHOW_CANCEL_MODAL,
    HIDE_CANCEL_MODAL,
} from '../actions/types';


const INITIAL_STATE = {
    show: false, locker: null
}


const cancelModalReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SHOW_CANCEL_MODAL:
            return { show: true, locker: {...action.payload} };
        case HIDE_CANCEL_MODAL:
            return { show: false, locker: null };
        default:
            return state;
    }
};

export default cancelModalReducer;
