import React from 'react'
import SkaparList from "./SkaparList";


const SkaparPage = () => {

    return (
        <div className="lg:flex items-center container mx-auto my-auto">
            <div className=" mx-auto my-auto">

                <div className="lg:m-4 shadow-md hover:shadow-lg hover:bg-gray-100 rounded-lg bg-white my-12 mx-8">
                    <img src="https://source.unsplash.com/random/1024x200" alt="" className="overflow-hidden" />
                    <div className="p-4">
                        <h3 className="font-medium text-gray-600 text-lg my-2 uppercase">Veldu þér skáp</h3>
                        <SkaparList />
                        <div className="mt-5">
                            Grænir skápar eru lausir til útleigu.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SkaparPage
