import {useMsal} from "@azure/msal-react";
import {signOut} from "../actions";
import React, {useRef} from "react";
import {connect} from "react-redux";

import { useDetectOutsideClick } from './useDetectOutsideClick';


const ProfileMenu = ({currentUserId}) => {
    const { instance } = useMsal();
    const handleLogout = () => {
        signOut();
        instance.logout();
    }

    const dropdownRef = useRef(null);
    const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);
    const onClick = () => setIsActive(!isActive);

    return (

        <div className="ml-3 relative" >
            <div>
                <button onClick={onClick} className="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white" id="user-menu" >
                    <span className="sr-only">Open user menu</span>
                    <img className="h-8 w-8 rounded-full" src="/cat.jpg" alt="" />
                </button>
            </div>
            <div ref={dropdownRef}>
                { isActive &&
                <div  className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5">
                    {/*<a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">Settings</a>*/}
                    <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem" onClick={handleLogout}>Sign out {currentUserId}</a>
                </div>}
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        currentUserId: state.auth.userId,
    };
};

export default connect(
    mapStateToProps,
    { signOut }
)(ProfileMenu);
