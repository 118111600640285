import skapar from '../apis/skapar';

import {
    SIGN_IN,
    SIGN_OUT,
    FETCH_SKAPAR,
    SHOW_CANCEL_MODAL,
    SHOW_RESERVE_MODAL,
    HIDE_RESERVE_MODAL,
    HIDE_CANCEL_MODAL,
    CANCEL_LOCKER,
    RESERVE_LOCKER,
} from './types';


export const fetchSkapar = (authToken) => async dispatch => {
    if (!authToken)
        return;

    const headers = {
        Authorization: "Bearer " + authToken
    }

    const response = await skapar.get('/getSkapar', {headers: headers});
    console.log(response);
    dispatch({ type: FETCH_SKAPAR, payload: response.data });
};


export const signOut = () => async dispatch => {
    dispatch({ type: SIGN_OUT, payload: null });
}


export const signIn = (userId, authToken) => async dispatch => {
    dispatch({ type: SIGN_IN, payload: {
            authToken,
            userId,
            isSignedIn: true
        }
    });
}


export const showReserveModal = (locker) => async dispatch => {
    dispatch({ type: SHOW_RESERVE_MODAL, payload: locker});
}

export const hideReserveModal = () => async dispatch => {
    dispatch({ type: HIDE_RESERVE_MODAL, payload: null});
}

export const showCancelModal = (locker) => async dispatch => {
    dispatch({ type: SHOW_CANCEL_MODAL, payload: locker});
}

export const hideCancelModal = () => async dispatch => {
    dispatch({ type: HIDE_CANCEL_MODAL, payload: null});
}

export const cancelLocker = (locker_number, authToken) => async dispatch => {
    const headers = {
        Authorization: "Bearer " + authToken
    }
    try {
        await skapar.patch("/cancel", { number: locker_number }, {headers: headers} )
    } catch (error) {
        alert("Ekki tókst að segja upp skáp:" +  error.response)
        console.log(error);
        return
    }
    dispatch({ type: CANCEL_LOCKER, payload: {number: locker_number}});
}


export const reserveLocker = (locker_number, authToken) => async dispatch => {
    const headers = {
        Authorization: "Bearer " + authToken
    }
    let res;
    try {
        res = await skapar.post("/reserve", { number: locker_number }, {headers: headers} )
    } catch (error) {
        alert("Ekki tókst að leigja skáp:" +  error.response)
        console.log(error);
        return
    }

    dispatch({ type: RESERVE_LOCKER, payload: res.data});
}
