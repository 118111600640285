import React from "react";
import Modal from "./Modal";
import {connect} from "react-redux";
import {hideCancelModal, cancelLocker} from "../actions";


const CancellationModal = ({show, authToken, locker_number, cancelLocker, hideCancelModal}) => {
    if (!show) return null;

    const cancelAction = () => {
        console.log("cancelAction")
        cancelLocker(locker_number, authToken);
        hideCancelModal();
    }

    return (<Modal
            show={show}
            title="Segja upp skáp "
            onClose={hideCancelModal}
            actionText="Segja upp skáp"
            onAction={cancelAction}
    >Segja upp skáp númer {locker_number}</Modal>)
}

const mapStateToProps = state => {
    return {
        show: state.cancelModal.show,
        locker_number: state.cancelModal.locker && state.cancelModal.locker.number,
        authToken: state.auth.authToken,
        isSignedIn: state.auth.isSignedIn,
        currentUserId: state.auth.userId,
    };
};

export default connect(
    mapStateToProps,
    { hideCancelModal,cancelLocker }
)(CancellationModal);
