// Config object to be passed to Msal on creation


const CURRENT_URL = window.location.protocol + '//' + window. location.host + "/"


export const msalConfig = {
    auth: {
        clientId: "64c7b584-c2c0-4437-a4a4-a28c95214b9c",
        authority: "https://login.microsoftonline.com/d26843de-a4dc-4bb1-8b07-ad6d81b6cc1f",
        redirectUri: CURRENT_URL,
        postLogoutRedirectUri: CURRENT_URL
    }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
    scopes: ["email", "User.Read", "openid","api://64c7b584-c2c0-4437-a4a4-a28c95214b9c/Skapar.Reserve" ],
    extraQueryParameters: {domain_hint: 'organizations'}
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};
