import React from 'react'


const Footer = () => {

    return (
        <div className="mt-10 bottom-0 text-center">
            <h4 className="text-sm font-semibold text-gray-600 ">&copy;  2021 Opin Kerfi hf</h4>
        </div>
    )
}

export default Footer
