import jwt from "jsonwebtoken";

const verifyToken = async (bearerToken) => {
    console.log("enter verify token")
    var jwksClient = require('jwks-rsa');
    const client = jwksClient({
        jwksUri: `https://login.microsoftonline.com/d26843de-a4dc-4bb1-8b07-ad6d81b6cc1f/discovery/keys`
    });
    return new Promise(resolve => {
        function getKey(header, callback) {
            client.getSigningKey(header.kid, function (err, key) {
                var signingKey = key.publicKey || key.rsaPublicKey;
                callback(err, signingKey);
            });
        }

        const options = {}
        jwt.verify(bearerToken, getKey, options, function (err, decoded) {
            if (err) {
                console.log("verifyToken error", err)
            }
            resolve(decoded);
        });
    });
}

export default verifyToken;
