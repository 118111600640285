import React from "react";
import {connect} from "react-redux";
import {showCancelModal, showReserveModal} from "../actions";



const Locker = ({locker, currentUserId, showCancelModal, showReserveModal}) => {
    const lockerColor = (locker) => {
        return locker.reserved ? (locker.userEmail === currentUserId ? 'bg-yellow-500' : 'bg-red-600') : 'bg-green-600';
    }

    const onClickLocker = (locker) => {
        console.log("onClickLocker", locker)
        if (locker.userEmail === currentUserId) {
            showCancelModal(locker);
        } else if (!locker.userEmail) {
            console.log("launching showReserveModal", locker)
            showReserveModal(locker);
        }
    }

    return <a key={locker.number} onClick={() => onClickLocker(locker)}
                 title={locker.userEmail}>
        <div className={"p-4 " + lockerColor(locker)}>
            <div style={{margin: "0.2rem", textAlign: "center"}}>
                {locker.number}
            </div>
        </div>
    </a>
}

const mapStateToProps = state => {
    return {
        currentUserId: state.auth.userId,
        isSignedIn: state.auth.isSignedIn,
        authToken: state.auth.authToken,
    };
};

export default connect(
    mapStateToProps,
    { showReserveModal, showCancelModal }
)(Locker);
