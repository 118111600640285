import {
    SIGN_IN,
    SIGN_OUT
} from '../actions/types';

const INITIAL_STATE = {
    authToken: null,
    userId: null,
    isSignedIn: null,
}

const authReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SIGN_IN:
            return {
                authToken: action.payload.authToken,
                userId: action.payload.userId,
                isSignedIn: action.payload.isSignedIn,
            };
        case SIGN_OUT:
            return INITIAL_STATE;
        default:
            return state;
    }
};

export default authReducer;
