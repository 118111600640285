import _ from 'lodash';
import {
    CANCEL_LOCKER,
    FETCH_SKAPAR, RESERVE_LOCKER,
} from '../actions/types';


const skaparReducer = (state = {}, action) => {
    switch (action.type) {
        case FETCH_SKAPAR:
            return { ...state, ..._.mapKeys(action.payload, 'id') };
        case CANCEL_LOCKER:
            return _.filter(state, (o) => o.number !== action.payload.number)
        case RESERVE_LOCKER:
            return { ...state, [action.payload.number]: action.payload };
        default:
            return state;
    }
};

export default skaparReducer;
