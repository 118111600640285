export const SIGN_IN = 'SIGN_IN';
export const SIGN_OUT = 'SIGN_OUT';
export const CREATE_STREAM = 'CREATE_STREAM';
export const FETCH_SKAPAR = 'FETCH_SKAPAR';
export const FETCH_STREAM = 'FETCH_STREAM';
export const DELETE_STREAM = 'DELETE_STREAM';
export const EDIT_STREAM = 'EDIT_STREAM';
export const SHOW_RESERVE_MODAL = 'SHOW_RESERVE_MODAL';
export const HIDE_RESERVE_MODAL = 'HIDE_RESERVE_MODAL';
export const SHOW_CANCEL_MODAL = 'SHOW_CANCEL_MODAL';
export const HIDE_CANCEL_MODAL = 'HIDE_CANCEL_MODAL';
export const CANCEL_LOCKER = 'CANCEL_LOCKER';
export const RESERVE_LOCKER = 'RESERVE_LOCKER';
