import {
     SHOW_RESERVE_MODAL,
    HIDE_RESERVE_MODAL,
} from '../actions/types';


const INITIAL_STATE = {
    show: false, locker: null
}


const reserveModalReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SHOW_RESERVE_MODAL:
            return { show: true, locker: {...action.payload} };
        case HIDE_RESERVE_MODAL:
            return { show: false, locker: null };
        default:
            return state;
    }
};

export default reserveModalReducer;
