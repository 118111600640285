import React from "react";
import Modal from "./Modal";
import {connect} from "react-redux";
import {hideReserveModal, reserveLocker} from "../actions";


const ReservationModal = ({show, authToken, locker_number, reserveLocker, hideReserveModal}) => {
    if (!show) return null;

    const reserveAction = () => {
        console.log("reserveAction")
        reserveLocker(locker_number, authToken);
        hideReserveModal();
    }

    return (<Modal
        show={show}
        title="Leigja skáp"
        onClose={hideReserveModal}
        actionText="Leigja"
        onAction={reserveAction}
    >Leigja skáp númer {locker_number}</Modal>)
}

const mapStateToProps = state => {
    return {
        show: state.reserveModal.show,
        locker_number: state.reserveModal.locker && state.reserveModal.locker.number,
        authToken: state.auth.authToken,
        isSignedIn: state.auth.isSignedIn,
        currentUserId: state.auth.userId,
    };
};

export default connect(
    mapStateToProps,
    { hideReserveModal,reserveLocker }
)(ReservationModal);
